const OPTIONS = {
  year: 'numeric' as const,
  month: 'long' as const,
  day: 'numeric' as const,
};

/**
 * By default the date will use the user's timezone
 * (e.g. "2023-07-22" could be displayed as "July 22" or "July 21").
 * Set `useUtc: true` to display the same date no matter the user's timezone.
 */
const formatDate = (
  value: string | number | Date,
  { locale = 'en-US', useUtc = false } = {}
) => {
  const date = value instanceof Date ? value : new Date(value);

  // not all browsers have Intl API
  if (typeof Intl === 'undefined' || !Intl.DateTimeFormat) {
    return useUtc ? date.toUTCString() : date.toString();
  }

  return new Intl.DateTimeFormat(
    locale,
    useUtc ? { ...OPTIONS, timeZone: 'UTC' } : OPTIONS
  ).format(date);
};

export default formatDate;
