import React from 'react';
import Box from '~/app/components/Box';
import { PageSectionComponent } from '../types';
import { ShowsSectionProps } from './types';
import { useRegisterNavItem } from '../../ItemPageNav';
import { useResolveShows } from './utils';
import ShowList from './ShowList';

const ShowsSection: PageSectionComponent<ShowsSectionProps> = ({
  title,
  items,
  geolocation,
  sectionId,
  sectionIndex,
  navTitle = 'Shows',
}) => {
  if (!items?.length) {
    return null;
  }

  const shows = useResolveShows(items, geolocation);

  useRegisterNavItem({
    id: sectionId,
    text: navTitle,
    index: sectionIndex,
  });

  if (!shows.length) {
    return null;
  }

  return (
    <Box testId="showsSection">
      <ShowList items={shows} title={title} />
    </Box>
  );
};

export default ShowsSection;
