import haversineDistance from 'haversine-distance';
import orderBy from 'lodash-es/orderBy';

import { ArtistShow, Geolocation } from '~/app/lib/songwhipApi/types';
import { MappedShow, ShowsSectionProps } from './types';

export const useResolveShows = (
  shows: ArtistShow[],
  geolocation?: ShowsSectionProps['geolocation']
) => {
  if (!shows.length) {
    return [];
  }

  // Filter out passed shows
  const showsFiltered = shows.filter((show) => {
    return Date.parse(show.localDate) > Date.now();
  });

  // Map shows if the user's geolocation is provided
  const showsMapped = (
    geolocation ? mapShows(showsFiltered, geolocation) : showsFiltered
  ) as MappedShow[];

  // Order shows by closest to the user and earliest
  const showsOrdered = orderBy(showsMapped, ['distanceFromUser', 'localDate']);

  return showsOrdered;
};

const mapShows = (shows: ArtistShow[], geolocation: Geolocation) => {
  const { latitude, longitude } = geolocation;

  return shows.map((show) => {
    let distanceFromUser;

    // Calculate the distance between the user's location and the venue's location
    if (show.geolocation) {
      distanceFromUser = haversineDistance(
        { latitude, longitude },
        {
          latitude: show.geolocation.latitude,
          longitude: show.geolocation.longitude,
        }
      ) as number;
    }

    return {
      ...show,
      distanceFromUser,
    } as MappedShow;
  });
};
