import React, { memo } from 'react';
import { MappedShow } from './types';
import Box, { BoxProps } from '~/app/components/Box';
import Text from '~/app/components/Text';
import formatDate from '~/app/lib/utils/formatDate';
import { useI18n } from '~/app/lib/i18n';
import Link from '~/app/components/Link2';

const ShowListItem = memo<
  {
    item: MappedShow;
  } & BoxProps
>(({ item, ...boxProps }) => {
  const { t } = useI18n();

  return (
    <Box
      testId="showListItem"
      flexRow
      alignCenter
      padding="1.4rem"
      style={{
        border: 'solid 1px #333',
        borderRadius: '0.5rem',
        background: '#111',
        justifyContent: 'space-between',
        gap: '1rem',
      }}
      {...boxProps}
    >
      <Box>
        <Text
          color="#e6e6e6"
          size="1.5rem"
          title={`${item.venueName} (${item.name})`}
          lineClamp={3}
          margin="0 0 1rem"
        >
          {
            // Some shows appear to have an empty `venueName` so we fallback to the event name in that case.
            item.venueName || item.name
          }
        </Text>
        <Text color="#808080" size="1.2rem" margin="0.1rem 0 0 0">
          {
            // We set `useUtc: true` here because we want to display the same date for all users,
            // no matter in which timezone they are
            `${formatDate(item.localDate, { useUtc: true })}${
              // REVIEW: We display the distance betwen the user and the venue in kilometers,
              // but we could improve this by picking a better unit depending on the user's country.
              item.distanceFromUser
                ? ` ∙ ${t('item.shows.distance', {
                    distance: Math.round(item.distanceFromUser / 1000),
                  })}`
                : ''
            }`
          }
        </Text>
      </Box>
      <Box noFlexShrink>
        <Link href={item.url}>
          <Text size="1.6rem" isBold>
            {t('item.shows.tickets')}
          </Text>
        </Link>
      </Box>
    </Box>
  );
});

export default ShowListItem;
